export const vhRef = { current: window.innerHeight };

function setViewportHeight() {
  vhRef.current = window.innerHeight;
  document.documentElement.style.setProperty(
    "--100vh",
    `${window.innerHeight}px`,
  );
}

window.addEventListener("resize", setViewportHeight);

setViewportHeight();
